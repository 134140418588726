.dropdown{
    position: relative;

    .dropdown__content{
        display: none;
        position: absolute;
       	width: 230px;
        z-index: 999;
        top: 60px;
        padding-left: 0;
		transition: all .4s linear;
		background: #0051a4;
		padding: 20px 0;

		li{
			width: 100%;
			height: 30px;
			padding: 0;
			padding-left: 12px;
			position: relative;

			&::after{
				content: '';
				width: 170px;
				height: 1px;
				background: #0062b1;
				position: absolute;
				left: calc(50% - 85px);
				bottom: 0;
			}

			&:last-child{
				&::after{
					display: none;
				}
			}


			a{
				width: 100%;
				height: 100%;
				padding-left: 20px;
				padding-right: 20px;
				font-size: 15px;
				font-family: 'latoregular';
				color: #fff;
			}

		}

		&::before{
			content: '';
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #0051a4;
			position: absolute;
			top: -10px;
			left: 30px;
		}

		&::after{
			content: '';
			width: 100%;
			height: 30px;
			background: transparent;
			position: absolute;
			top: -30px;
		}

    }




}

.dropdown:hover .dropdown__content {
    display: block;
  }


/********************************************************************************
						MOBILE/TABLET
********************************************************************************/
@include media-breakpoint-down(md){
	.dropdown{
		.dropdown__content{
			width: 250px !important;
			height: auto;
			top: 34px;
		}
	}

	.dropdown:hover .dropdown__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	  }
}
