.rodape{
	font-size: 13px;
	padding-bottom:30px;

	.creditos{
		padding-top: 10px;
		border-bottom:1px solid #ccc;
	}

	.bg-grey{
		background: #eee;
		padding-top:10px;
		padding-bottom:10px;
	}

	ul{
		list-style: none;
		padding-left:0;
		margin-bottom:0;

		li{
			margin-bottom: 10px;
		}
	}

	.nav__footer{
		@include media-breakpoint-up(lg) {
			columns: 2;
		}
	}

	.midias-sociais{
		font-size: 20px;

		.fa{
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			border:2px solid #0081be;
			color:#0081be;
			background: #FFF;
			border-radius:100%;
			margin:5px;

			&:hover{
				color: #0081be;
				text-decoration: none;
			}
		}
	}
}

.title-footer{
	font-family: 'latoblack';
	font-size: 15px;
	color: #505050;
	margin-top:20px;
}

@include media-breakpoint-down(sm){
	.creditos{
		.row > * + *{
			margin-top:15px;
		}
	}
}

@include media-breakpoint-down(md){
	.autor{
		text-align:center;
	}
}

@include media-breakpoint-up(lg){
	.creditos{
		.col-xl-3:last-child{
			display:flex;
			align-items: center;
			justify-content:flex-end;
		}

		.gv8{
			margin-left:10px;
		}
	}
}