.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}


.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
    @extend .img-fluid;
}

.btn-blue{
    @include button-variant(#0051a4, #0051a4);
    color: #FFF;
}

.btn{
    white-space: normal;
}

.btn-pill{
    border-radius:30px;
}

lazyimage{
    display:block;
}

img{
    max-width:100%;
    height: auto !important;
}

@mixin alerta-variant($theme, $color){
    background: $theme;
    color: $color;
}

.alerta{
    position:fixed;
    bottom:30px;
    right:15px;
    width:300px;
    max-width:calc(100% - 30px);
    z-index:999;
    padding:10px;
    border:1px solid rgba(#000,0.2);
    background: #FFF;
    box-shadow:0 0 8px rgba(#000,0.4);

    &.alerta-default{
        @include alerta-variant(materialColor('grey', 'darken-2'), #fff);
    }

    &.alerta-warning{
        @include alerta-variant(materialColor('orange', 'darken-2'), #fff);
    }

    &.alerta-primary{
        @include alerta-variant(materialColor('blue', 'darken-2'), #fff);
    }

    &.alerta-success{
        @include alerta-variant(materialColor('green', 'darken-2'), #fff);
    }

    &.alerta-danger{
        @include alerta-variant(materialColor('red', 'darken-2'), #fff);
    }
}

.modal{
    .modal-header,
    .modal-body,
    .modal-footer,
    .modal-content{
        border-radius:0;
    }

    &.modal-default{
        .modal-header{
            background: materialColor('grey', 'darken-2');
            color:#FFF;
        }
    }

    &.modal-success{
        .modal-header{
            background: materialColor('green', 'darken-2');
            color:#FFF;
        }
    }

    &.modal-warning{
        .modal-header{
            background: materialColor('orange', 'darken-2');
            color:#FFF;
        }
    }

    &.modal-danger{
        .modal-header{
            background: materialColor('red', 'darken-2');
            color:#FFF;
        }
    }

    &.modal-primary{
        .modal-header{
            background: materialColor('blue', 'darken-2');
            color:#FFF;
        }
    }
}

.btn-orange{
    @include button-variant(#FF8400,#FF8400);
    color: #FFF;
}