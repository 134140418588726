#banner{
	figure{
		margin-bottom:0;
	}
}

.section-heading{
	padding-bottom:10px;
	border-bottom:5px solid #e1e1e1;
	font-family: 'latolight';
	margin-bottom:20px;

	&:before{
		content: '';
		display: block;
		width:60px;
		height: 5px;
		background: #00afef;
		margin-bottom:10px;
	}

	&.section-heading-2{
		&:before{
			display: none;
		}

		h2{
			font-family: 'futurabt';
			color: #0051A4;
		}
	}
}

#banner-palavraParoca{
	position: relative;

	.controles a{
		position: absolute;
		z-index: 5;
		top:50%;
		transform: translateY(-50%);
		color: #FFF;
		text-shadow: 0 0 8px rgba(#000,0.65);
		font-size: 30px;

		&.prev{
			left: 10px;
		}

		&.next{
			right: 10px;
		}
	}
}

.agendas-index{

	.agenda{
		padding:10px 0;

		& + .agenda{
			border-top:1px solid #CCC;
		}

		.data{
			color: #0051a4;
			text-align: center;
		}

		.dia{
			font-size:40px;
			font-family: 'latoblack';
		}

		.mes{
			font-size:15px;
		}
	}

	a:hover{
		text-decoration:none;
		@extend .text-orange;
	}
}

.noticia-index{
	padding:15px 0;
	border-bottom:1px solid #ccc;
	color: #666666;
	font-size:14px;

	.title-noticia{
		font-size:17px;
		color: #0051a4;
		margin-bottom:10px;
		font-family: 'open_sansbold';
	}

	.desc{
		margin-bottom:10px;
	}
}