@import '_dropdowns';

.topo{
	.faixa-azul{
		background: #0051a4;
		color:#FFF;

		span{
			display:inline-block;
			margin-right:10px;
		}

		a:hover{
			color: #FFF;
		}

		.content{
			max-width: 1170px;
			padding-left:15px;
			padding-right:15px;
			margin-left:auto;
			margin-right:auto;
			display: flex;
			flex-wrap:wrap;
			align-items: center;

			@include media-breakpoint-up(lg){
				justify-content:space-between;
			}
		}

		.content > *{
			padding-top:10px;
			padding-bottom:10px;
		}
	}

	.btn__entrar{
		width: 145px;
		height: 30px;
		border-radius: 5px;
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 12px;
		font-family: 'open_sansbold';
		font-weight: 700;
		background: #0093d9;
		text-decoration: none;

		@include media-breakpoint-down(md){
			margin-bottom: 15px;
		}

		i{
			margin-right: 10px;
			font-size: 18px;
		}
	}

	.midias-sociais{
		.fa{
			display: inline-block;
			margin-left:10px;
			margin-right:10px;
		}

		.fa:hover{
			text-decoration:none;
		}
	}

	figure{
		margin-bottom:0;
	}

	ul{
		list-style:none;
		padding-left:0;
		margin-bottom:0;
	}

	.mobile-controls{
		border-bottom:1px solid rgba(#000,0.2);
		background: #FFF;

		&.fx{
			position:fixed;
			top:0;
			left:0;
			width:100%;
			z-index:999;
			box-shadow:0 0 8px rgba(#000,0.4);
		}

		.btn{
			color: #00afef;
		}
	}
}

.bg-menu{
	display: none;

	width:100%;
	height:100%;

	background: rgba(#000,0.8);

	position: fixed;
	left:0;
	top:0;
	z-index:999;
}

#menu{
	& > li > a{
		display:block;
		padding:10px 10px;
		color: #0051a4;
	}

	& > .active > a,
	& > li:hover > a{
		background: #add5e8;
	}

	.dropdown-menu{
		border-radius:0;
		background: #0051a4;
		color: #FFF;

		a{
			padding: 10px 10px;
			display:block;
		}

		.active a,
		a:hover{
			background: darken(#0051a4, 10%);
			text-decoration: none;
			list-style: none;
			color: #FFF;
		}
	}
}

@include media-breakpoint-up(xl){
	.mobile-controls{
		display: none;
	}

	.nav-topo{
		.content{
			max-width:1170px;

			padding-left:15px;
			padding-right:15px;

			margin-right:auto;
			margin-left:auto;

			padding-top:10px;
			padding-bottom:10px;

			display:flex;
			align-items: center;
		}
	}

	#menu{
		flex-grow:1;
		display: flex;
		justify-content: space-between;

		padding-left:30px;
		padding-right:30px;

		& > li > a{
			border-radius: 4px;
		}

		.dropdown-menu{
			width: 220px;

			&:before{
				content: '';
				display: block;
				border-left:10px solid transparent;
				border-right:10px solid transparent;
				border-bottom:10px solid #0051a4;
				position:absolute;
				bottom:100%;
				left:10px;
			}

			li + li:before{
				content:'';
				display: block;
				max-width: 90%;
				margin: 0 auto;
				border-top:1px solid rgba(#fff,0.2);
			}
		}
	}
}

@include media-breakpoint-only(lg){
	.mobile-controls{
		display: none;
	}

	.nav-topo{
		.content{
			max-width:1170px;

			padding-left:15px;
			padding-right:15px;

			margin-right:auto;
			margin-left:auto;

			padding-top:10px;
			padding-bottom:10px;

			display:flex;
			align-items: center;
			flex-wrap: wrap;
		}
	}

	.brand{
		order:1;
		flex-grow: 1;
	}

	.brasao{
		order:2;
	}

	#menu{
		flex-grow:1;
		display: flex;
		justify-content: space-between;

		margin-top:15px;

		order:3;

		& > li > a{
			border-radius: 4px;
		}

		.dropdown-menu{
			width: 220px;

			&:before{
				content: '';
				display: block;
				border-left:10px solid transparent;
				border-right:10px solid transparent;
				border-bottom:10px solid #0051a4;
				position:absolute;
				bottom:100%;
				left:10px;
			}

			li + li:before{
				content:'';
				display: block;
				max-width: 90%;
				margin: 0 auto;
				border-top:1px solid rgba(#fff,0.2);
			}
		}
	}
}

@include media-breakpoint-only(md){
	.topo{

		.faixa-azul{
			text-align: center;

			.content{
				justify-content: center;

				.contato-topo,
				.midias-sociais{
					width:100%;
				}
			}
		}
	}

	.mobile-controls{
		display: flex;
		justify-content: space-between;

		.brand{
			width:220px;
			padding:10px 15px;
		}

		.btn{
			border-radius:0;
		}
	}

	.open .nav-topo{
		left:0;
	}

	.nav-topo{
		position: fixed;

		width: 250px;
		height: 100%;
		overflow: auto;

		left:-250px;
		top:0;

		background:#FFF;

		z-index:1000;
		border-right:1px solid #ccc;
		transition:left 1s linear;

		.brand{
			padding: 30px 15px 20px 15px;
		}

		#menu {
			& > li > a{
				border-bottom:1px solid rgba(#000,0.2);
			}

			.dropdown-menu{
				width:100%;
			}
		}

		.brasao{
			padding: 10px 15px;
			text-align: center;
		}
	}
}

@include media-breakpoint-down(sm){
	.topo{

		.faixa-azul{
			text-align: center;

			.content{
				justify-content: center;
			}

			span{
				display:block;
			}
		}
	}

	.mobile-controls{
		display: flex;
		justify-content: space-between;

		.brand{
			width:190px;
			padding:10px 15px;
		}

		.btn{
			border-radius:0;
		}
	}

	.open .nav-topo{
		left:0;
	}

	.nav-topo{
		position: fixed;

		width: 250px;
		height: 100%;
		overflow: auto;

		left:-250px;
		top:0;

		background:#FFF;

		z-index:1000;
		border-right:1px solid #ccc;
		transition:left 1s linear;

		.brand{
			padding: 30px 15px 20px 15px;
		}

		#menu {
			& > li > a{
				border-bottom:1px solid rgba(#000,0.2);
			}

			.dropdown-menu{
				width:100%;
			}
		}

		.brasao{
			padding: 10px 15px;
			text-align: center;
		}
	}
}
