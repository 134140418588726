@import '_area_restrita';

.page-header{
	padding:30px 0;
	text-align: center;
	background: #EBEBEB;
	margin-bottom:70px;

	&.mgb-0{
		margin-bottom:0;
	}

	h1{
		color: #0051A4;
		font-family: 'futurabt';
	}

	ol{
		list-style:none;
		padding-left:0;

		li{
			display: inline-block;
		}

		li + li:before{
			content:'/';
			margin:0 10px;
		}

		span{
			color: #0051A4;
		}
	}

	.detail-page-header{
		border-bottom:1px solid rgba(#000,0.2);
		max-width:200px;
		margin-left:auto;
		margin-right:auto;

		&:before{
			content:'';
			display: block;
			width:60px;
			height:5px;
			background: #0051A4;
			margin:0 auto;
		}
	}
}

.video{
	max-width: 270px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:30px;
	font-size:16px;
	text-align:center;
	color: #777777;

	.thumbnail{
		position:relative;
		background: #000;

		&:before{
			content:'';
			display: block;
			width:46px;
			height:46px;
			background: url(#{$imgs}/play-video.png) center center no-repeat;
			position:absolute;
			left: calc(50% - 23px);
			top: calc(50% - 23px);
		}
	}

	a:hover{
		lazyimage{
			opacity:0.8;
		}
	}
}

#amplia-video{
	.modal-dialog{
		max-width:720px;
	}
}

.artigo{
	.title{
		color: #0051A4;
		font-size:20px;
		font-family: 'futurabt';
		margin-bottom: 10px;
	}

	.description{
		margin-bottom:10px;
	}

	.btn{
		padding-left:30px;
		padding-right:30px;
	}
}

.panel{
	border:1px solid rgba(#000,0.2);

	.panel-header,
	.panel-body{
		padding:10px;
	}

	.panel-header{
		border-bottom:1px solid rgba(#000,0.2);
		font-size:26px;
	}

	&.panel-primary{
		.panel-header{
			background: #0051A4;
			color:#FFF;
		}
	}

	&.panel-danger{
		.panel-header{
			background: materialColor('red','darken-2');
			color:#FFF;
		}
	}

	&.panel-success{
		.panel-header{
			background: materialColor('green','darken-2');
			color:#FFF;
		}
	}

	.artigo{
		max-width:350px;
		margin-left:auto;
		margin-right:auto;
		padding-bottom:15px;
		margin-bottom:15px;
		border-bottom:1px solid rgba(#000,0.2);
	}
}

#missasDeHoje{
	overflow:hidden;

	.panel-header{
		font-size: 20px;
	}

	.ctrls{
		position:absolute;
		right:0;
		top:0;
		padding:11px;
		z-index:10;
		color:#FFF;
	}

	.carousel-ctrls{
		display: inline-block;
		padding:0 5px;

		& + .carousel-ctrls{
			margin-left:5px;
		}
	}

	.turno{
		padding:10px;
		border-left:1px solid rgba(#000,0.2);
		border-right:1px solid rgba(#000,0.2);

		.title{
			color: #0051A4;
		}

		&:nth-child(odd){
			background: #EBEBEB;
		}

		&:nth-child(even){
			background: #FFFFFF;
		}

		& + .turno{
			border-top:1px solid rgba(#000,0.2);
		}
	}
}

#albumComunidade{
	padding: 0 48px;
	position: relative;

	.owl-next,
	.owl-prev{
		@extend .btn, .btn-blue;
		height:100%;
		align-items:center;
		display:flex;
		position:absolute;
		top: 0;
		border-radius: 0;
	}

	.owl-prev{
		left:0;
	}

	.owl-next{
		right:0;
	}
}

#contato{
	label{
		@extend .sr-only;
	}

	.form-control{
		border-radius:0;
	}
}

#mapa-footer{
	padding-bottom: (420 / 1920) * 100%;
	min-height:200px;
}

.link-util{
	text-align: center;
}

#liturgia{
	display:flex;
	flex-wrap: wrap;

	.nav-item{
		flex-grow:1;
		text-align:center;
	}

	.nav-link{
		padding-left:2em;
		padding-right:2em;
		border:1px solid rgba(#000,0.1);
		color: #777;
		border-radius:0;

		&.active,
		&:hover{
			background: #FFF;
			@include gradient-y(#FFF,#E7E7E7);
			color: #777;
		}
	}
}

.album{
	text-align:center;
	margin-bottom:30px;
	max-width:270px;
	margin-left:auto;
	margin-right:auto;

	.foto{
		margin-bottom:10px;
		position:relative;

		&:before{
			content:'VISUALIZAR ÁLBUM';
			position:absolute;
			display: flex;
			align-items:center;
			justify-content: center;
			color: #FFF;
			background: rgba(#337ab7,0.68);
			top:0;
			left:0;
			z-index:2;
			width:100%;
			height:100%;
			opacity:0;
			transition:all 0.3s linear;
		}
	}

	&:hover{
		.foto:before{
			opacity:1;
		}
	}
}


.modal-fotos{
	.modal-dialog{
		max-width:900px;
	}
	.modal-content{
		background-color: transparent;

		box-shadow:none;
		border:none;
		min-height:500px;
	}

	&.loading .modal-content{
		background-image: url(#{$imgs}/loading.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size:60px 60px;
	}

	.carousel{
		position:relative;

		.ctrls{
			a{
				@extend .btn,.btn-light;
				border-radius:0;
				position:absolute;
				top: calc(50% - 30px);
				padding-top:15px;
				padding-bottom:15px;
				z-index:4;
				height:60px;

				&[data-carousel="prev"]{
					left:10px;
				}

				&[data-carousel="next"]{
					right:10px;
				}
			}
		}
	}

	.carousel-indicators{
		position:relative;
		width: 100%;
		display:block;
		padding-bottom:10px;
		white-space:nowrap;
		overflow:auto;
		margin:0;

		li{
			display: inline-block;
			height:auto;
			width:145px;
			text-indent:0;
			border:2px solid transparent;
			background: none;
			margin:0;
			position:relative;

			&.active{
				border-color: #3081F9;
			}
		}
	}
}

.pastorais{
	max-width:270px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:30px;
	font-size:15px;
	text-align:center;

	.foto{
		margin-bottom:10px;
	}
}

.bg-vela{
	padding:30px 0;
	min-height:681px;
	background-size:cover;
	backgorund-position: center center;
	background-attachment: fixed;
}

.btn-vela{
	background: #000;
	color:#FFF;

	&:hover,
	&.active{
		background: #0051A4;
		border-color:#0051A4;
		color:#FFF;
	}
}

.form-vela{
	padding: 60px 30px;
	background: rgba(#000,0.6);
	border-radius:30px;
	color:#FFF;

	.hdd-label label{
		@extend .sr-only;
	}

	.form-control{
		border-radius:30px;
	}
}

.vela-acesa{
	background: #fff;
	border-radius:15px;
	padding:15px;
	margin-bottom:15px;
	color:#777;

	.nome{
		font-size:18px;
	}

	@include media-breakpoint-down(xs){
		text-align:center;

		.nome{
			margin-bottom:10px;
			padding:10px;
			border-bottom:2px solid #ACACAC;
		}

		.col-sm-5{
			text-align:left;
		}
	}

	@include media-breakpoint-up(sm){
		.col-sm-5{
			border-left:2px solid #ACACAC;
		}
	}
}

#modalErro{
	.modal-header{
		border-bottom: 0;
	}

	.close{
		color: #fff;
		font-size: 14px;
	}

	.modal-content{
		background: transparent;
		border: 0;
	}

	.modal-body{
		background: rgb(184, 8, 8);
		width: 100%;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	.title{
		text-align: center;
		color: #fff;
		font-family: 'latoregular';
	}
}

#modalLogin{

	.modal-content{
		background: transparent;
		border: 0;
	}

	.modal-header{
		border-bottom: 0;
		width: 100%;
		max-width: 540px;
		margin-left: auto;
		margin-right: auto;

		.close{
			font-size: 12px;
			color: #fff;
			opacity: 1;
			font-family: 'latoregular';
			font-weight: 400;
			text-shadow: unset;
			padding: 0;
		}
	}

	.modal-body{
		width: 100%;
		max-width: 540px;
		padding-top: 50px;
		padding-bottom: 50px;
		border: 0;
		background-color: #fff;
		margin-left: auto;
		margin-right: auto;
	}

	.title{
		text-align: center;
		color: #0051A4;
		margin-bottom: 10px;
		font-size: 32px;
		line-height: 32px;
		font-family: 'latoblack';
	}

	.descritivo{
		font-size: 15px;
		line-height: 17px;
		margin-bottom: 30px;
		color: #666666;
		text-align: center;
		font-family: 'latoregular';
	}

	.form__login{
		width: 100%;
		max-width: 435px;
		margin-left: auto;
		margin-right: auto;

		label{
			@extend .sr-only;
		}

		input{
			width: 100%;
			height: 48px;
			border-radius: 0;
			padding-left: 20px;
			border: 1px solid #d7d7d7;
			background: #ebebeb;
			font-family: 'latoregular';
			font-size: 15px;
			color: #666666;

			&::placeholder{
				font-family: 'latoregular';
				font-size: 15px;
				color: #666666;
			}

			&:last-child{
				margin-bottom: 0;
			}
		}

		.btn__submit{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 145px;
			height: 40px;
			border-radius: 5px;
			background: #00afef;
			color: #fff;
			font-size: 15px;
			font-family: 'latoregular';
			margin-left: auto;
			margin-right: auto;
			margin-top: 10px;
			transition: background .4s linear;

			&:hover{
				background: darken(#00afef, 15%);
			}
		}

		.form-group{
			margin-bottom: 20px;
		}
	}
}

