.area__restrita{
	padding-bottom: 80px;

	.descritivo{
		font-size: 15px;
		line-height: 17px;
		margin-bottom: 30px;
		color: #666666;
		text-align: center;
		font-family: 'latoregular';
	}

	.btn__submit, .btn__baixar{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 145px;
		height: 40px;
		border-radius: 5px;
		background: #00afef;
		color: #fff;
		font-size: 15px;
		font-family: 'latoregular';
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		transition: background .4s linear;

		&:hover{
			background: darken(#00afef, 15%);
		}
	}

	.btn__baixar{
		margin-top: 50px;
	}

	.form__area__restrita{
		width: 100%;
		max-width: 540px;
		margin-left: auto;
		margin-right: auto;

		label{
			@extend .sr-only;
		}

		input{
			width: 100%;
			height: 48px;
			border-radius: 0;
			margin-bottom: 20px;
			padding-left: 20px;
			border: 1px solid #d7d7d7;
			background: #ebebeb;
			font-family: 'latoregular';
			font-size: 15px;
			color: #666666;

			&::placeholder{
				font-family: 'latoregular';
				font-size: 15px;
				color: #666666;
			}

			&:last-child{
				margin-bottom: 0;
			}
		}

		select{
			width: 100%;
			height: 48px;
			border-radius: 0;
			padding-left: 20px;
			border: 1px solid #d7d7d7;
			background: #ebebeb;
			font-family: 'latoregular';
			font-size: 15px;
			color: #666666;

			&::placeholder{
				font-family: 'latoregular';
				font-size: 15px;
				color: #666666;
			}

			&:last-child{
				margin-bottom: 0;
			}
		}


	}

	.form__busca{
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 920px !important;

		@include media-breakpoint-down(md){
			flex-direction: column;
		}

		input{
			width: 350px;
			margin-bottom: 0 !important;
			margin-right: 30px;

			@include media-breakpoint-down(md){
				margin-right: 0;
				margin-bottom: 30px !important;
				width: 100% !important;
			}
		}

		select{
			width: 350px;
			margin-bottom: 0 !important;
			margin-right: 30px;

			@include media-breakpoint-down(md){
				margin-right: 0;
				margin-bottom: 30px !important;
				width: 100% !important;
			}
		}

		.select__personalizado{
			width: 100%;
		}

		.form-group{
			margin-bottom: 0;
			width: 100%;
		}

		.btn__submit{
			margin-top: 0;
		}
	}

	.big{
		width: 100%;
		max-width: 730px !important;
	}

	.segura__categorias{
		display: flex;
		justify-content: center;
		align-items: center;

		@include media-breakpoint-down(sm){
			flex-direction: column;
		}
	}

	.card__categoria{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 30px 0;
		width: 252px;
		border: 1px solid #d7d7d7;
		background-color: #ebebeb;
		transition: background .4s linear;
		margin-right: 30px;
		text-decoration: none;

		@include media-breakpoint-down(sm){
			margin-bottom: 30px;
			margin-right: 0;
		}

		&:last-child{
			margin-right: 0;

			@include media-breakpoint-down(sm){
				margin-bottom: 0px;
			}
		}

		&:hover{
			background: #00afef;

			.icone{
				background: #fff;
			}

			.nome{
				color: #fff;
			}
		}

		.icone{
			width:51px;
			height:51px;
			--bg : url(../imagens/conteudo/icone-categoria.png);
			-webkit-mask-image: var(--bg, url(../imagens/conteudo/icone-categoria.png));
			mask-image: var(--bg, url(../imagens/conteudo/icone-categoria.png));
			-webkit-mask-position: center center;
			mask-position: center center;
			-webkit-mask-size: contain;
			mask-size: contain;
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			background: #666666;
			transition: background .4s linear;
		}

		.nome{
			font-size: 18px;
			line-height: 20px;
			color: #666666;
			font-family: 'latoblack';
			transition: color .4s linear;
			text-align: center;
			margin-top: 15px;
		}
	}

	.nome__usuario{
		text-align: center;
		margin-top: 80px;
		margin-bottom: 35px;
		font-size: 14px;
		line-height: 26px;
		color: #666666;
		font-family: 'latoblack';
	}

	.segura__arquivos{
		margin-top: 100px;
		border-radius: 0;
		border: 1px solid #d7d7d7;
		width: 100%;
		max-width: 920px;
		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-down(sm){
			border: 0;
		}
	}

	.arquivo{
		display: flex;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid #d7d7d7;
		text-decoration: none;
		background: #ebebeb;
		color: #666666;

		@include media-breakpoint-down(sm){
			flex-direction: column;
			margin-bottom: 30px;
		}

		&:first-child{
			.nome__arquivo{
				@include media-breakpoint-up(md){
					&::after{
						content: 'NOME DO ARQUIVO';
						width: 100%;
						height: auto;
						font-size: 14px;
						font-family: 'latoblack';
						color: #666666;
						position: absolute;
						left: 0;
						top: -30px;
					}
				}
			}

			.email__arquivo{
				@include media-breakpoint-up(md){
					&::after{
						content: 'E-MAIL';
						width: 100%;
						height: auto;
						font-size: 14px;
						font-family: 'latoblack';
						color: #666666;
						position: absolute;
						left: 0;
						top: -30px;
					}
				}
			}

			.telefone__arquivo{
				@include media-breakpoint-up(md){
					&::after{
						content: 'TELEFONE';
						width: 100%;
						height: auto;
						font-size: 14px;
						font-family: 'latoblack';
						color: #666666;
						position: absolute;
						left: 0;
						top: -30px;
					}
				}
			}

			.endereco__arquivo{
				@include media-breakpoint-up(md){
					&::after{
						content: 'ENDEREÇO';
						width: 100%;
						height: auto;
						font-size: 14px;
						font-family: 'latoblack';
						color: #666666;
						position: absolute;
						left: 0;
						top: -30px;
					}
				}
			}

			.codigo__arquivo{
				@include media-breakpoint-up(md){
					&::after{
						content: 'CÓDIGO';
						width: 100%;
						height: auto;
						font-size: 14px;
						font-family: 'latoblack';
						color: #666666;
						position: absolute;
						left: 0;
						top: -30px;
					}
				}
			}
		}

		&:last-child{
			border-bottom: 0;
		}

		&:hover{
			.box__icone{
				background: #00afef;
				.icone{
					background: #fff;
				}
			}
		}

		.nome__arquivo{
			border-right: 1px solid #d7d7d7;
			font-family: 'latoregular';
			font-size: 13px;
			line-height: 16px;
			padding: 10px;
			color: #666666;
			width: 20%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			@include media-breakpoint-down(sm){
				border-right: 0;
				border-bottom: 1px solid #d7d7d7;
				width: 100%;
				justify-content: center;
				height: auto;
				padding: 10px 0;
			}
		}

		.email__arquivo{
			width: 20%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			border-right: 1px solid #d7d7d7;
			font-family: 'latoregular';
			font-size: 13px;
			line-height: 16px;
			color: #666666;
			padding: 10px;

			@include media-breakpoint-down(sm){
				border-right: 0;
				border-bottom: 1px solid #d7d7d7;
				width: 100%;
				justify-content: center;
				height: auto;
				padding: 10px 0;
			}
		}

		.telefone__arquivo{
			width: 20%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			border-right: 1px solid #d7d7d7;
			font-family: 'latoregular';
			font-size: 13px;
			line-height: 16px;
			color: #666666;
			padding: 10px;

			@include media-breakpoint-down(sm){
				border-right: 0;
				border-bottom: 1px solid #d7d7d7;
				width: 100%;
				justify-content: center;
				height: auto;
				padding: 10px 0;
			}
		}

		.endereco__arquivo{
			width: 20%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			border-right: 1px solid #d7d7d7;
			font-family: 'latoregular';
			font-size: 13px;
			line-height: 16px;
			color: #666666;
			padding: 10px;

			@include media-breakpoint-down(sm){
				border-right: 0;
				border-bottom: 1px solid #d7d7d7;
				width: 100%;
				justify-content: center;
				height: auto;
				padding: 10px 0;
			}
		}

		.codigo__arquivo{
			width: 20%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			border-right: 1px solid #d7d7d7;
			font-family: 'latoregular';
			font-size: 13px;
			line-height: 16px;
			color: #666666;
			padding: 10px;

			@include media-breakpoint-down(sm){
				border-right: 0;
				border-bottom: 1px solid #d7d7d7;
				width: 100%;
				justify-content: center;
				height: auto;
				padding: 10px 0;
			}
		}
	}
}
