body{
	font-family: 'latoregular';
	color:#777;
}

a{
	color:inherit;
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

@include text-emphasis-variant('.text-orange', #e69d47);
@include text-emphasis-variant('.text-grey', #999999);
@include text-emphasis-variant('.text-blue', #0051A4);
@include text-emphasis-variant('.text-dark', #000);

.lt-blk{
	font-family: 'latoblack';
}

.titles{
	font-size:24px;
	color: #0051A4;
	font-family: 'futurabt';
}

.futurabt{
	font-family: 'futurabt';
}